
import { Box } from "@mui/material";
import React, { Children, useMemo } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

/** Virtual List Sizing Constants */
const LISTBOX_PADDING = 8;
const ROW_HEIGHT = 68;
const MAX_ITEMS_TO_DISPLAY = 5;

/** 
 * This listbox is used in MUI's Autocomplete to render long lists efficiently. 
 * It uses react-window for windowed (virtualized) rendering. 
 */
export const VirtualizedListbox = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(
function VirtualizedListbox(props, ref) {
  const { children, ...other } = props;
  
  // Turn children into a flat array so we can map over them
  const items = useMemo(() => Children.toArray(children), [children]);
  const itemCount = items.length;

  const listHeight = Math.min(
    ROW_HEIGHT * MAX_ITEMS_TO_DISPLAY + 2 * LISTBOX_PADDING,
    ROW_HEIGHT * itemCount + 2 * LISTBOX_PADDING
  );

  // Render function for each row in react-window
 const renderRow = React.useCallback(
  ({ index, style }: ListChildComponentProps): JSX.Element => {
    return (
       <Box 
          sx={{
            ...style,
            my: 0,
            textWrap: "wrap",
            width: "100%",
            p: 1,
           "&:not(:first-of-type)": {
              mt: "4px",
            },
            "& .MuiAutocomplete-option": {
              py: 1,
              px:0,
            },
          }}
        >
          {items[index]}
        </Box>
      );
  },
  [items]
);

  return (
    <Box 
      ref={ref} 
      {...other}
      sx={{
        width: "100%",
        height: listHeight,
        overflow: "hidden",
        "& ul": {
          padding: 0,
          margin: 0,
          width: "100%",
        },
      }}
    >
      <FixedSizeList
        height={listHeight }
        width="100%"
        itemCount={itemCount}
        itemSize={ROW_HEIGHT}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
});
