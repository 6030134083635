import { Autocomplete, Box, ListItemText, Paper, Stack, SvgIcon } from "@mui/material";
import { SyntheticEvent, useMemo } from "react";
import { CoreAPITypes } from "@stellar/api-logic";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import GenericClose from "@assets/icons/generic-close_l.svg?react";
import SelectorArrow from "@assets/icons/selector_arrow.svg?react";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { formatUserRoleType, userRoleDescription } from "@utils/data-display";
import { SphereDivider } from "@components/common/sphere-divider";
import { ExternalLinks, LinkName } from "@components/external-links";
import { sphereColors } from "@styles/common-colors";
import { LabelWithHelp } from "@components/common/label-with-help";
import { RoleAutocompleteProps } from "@components/role/select-role-utils";
import { getAutocompleteStyles } from "@utils/ui-utils";
import { DataTestIdProps } from "@custom-types/ui-types";

interface Props<T extends CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole>
  extends DataTestIdProps, RoleAutocompleteProps<T> {
  /** The roles that can be selected */
  roles: T[];

  /** If provided, this will display a link to the corresponding Roles help page */
  link?: LinkName;

  /** The title of the label */
  labelTitle?: string;
}

export function RoleAutocomplete<
  T extends CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole
>({
  selectedRole,
  onChange,
  onResetSelection,
  placeholder = "Select a role...",
  roles,
  disabledRoles = [],
  link,
  labelTitle,
  isRequired = false,
  dataTestId,
}: Props<T>): JSX.Element {
  const styles = getAutocompleteStyles();
  function onSelectedRoleChanged(role: T | null): void {
    if (role) {
      onChange(role);
    } else if (onResetSelection) {
      onResetSelection();
    }
  }

  const roleOptions = useMemo(() => {
    return roles.map((role) => ({
      role,
      label: formatUserRoleType(role),
      description: userRoleDescription(role),
    }));
  }, [roles]);

  const selectedValue = useMemo(
    () => roleOptions.find((r) => r.role === selectedRole),
    [roleOptions, selectedRole]
  );

  return (
     <>
      {labelTitle && (
        <LabelWithHelp
          title={labelTitle}
          isRequired={isRequired}
        />
      )}
      <Autocomplete
        value={selectedValue || null}
        data-testid={dataTestId}
        options={roleOptions}
        onChange={(_, newValue) => onSelectedRoleChanged(newValue?.role ?? null)}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.role === value.role}
        getOptionDisabled={(option) => disabledRoles.includes(option.role)}
        popupIcon= {
          <Box
            sx={styles.iconWrapper.sx}>
            <SelectorArrow />
          </Box>
        }

        clearIcon={!isRequired && (
          <Box
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
              onSelectedRoleChanged(null);
              onResetSelection?.();
            }}
            sx={styles.iconWrapper.sx}
          >
            <GenericClose />
          </Box>
        )}

        renderOption={(props, option, state) => (
          <Box
            component="li"
            {...props}
            key={`role-item-${state.index}`}
            sx={styles.option.sx}
          >
            <ListItemText
              sx={styles.listItemText.sx}
              primary={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ position: "relative" }}
                  >
                    {option.label}
                    {option.role === selectedRole && (
                      <SvgIcon
                        inheritViewBox
                        component={GenericCheck}
                        sx={{ 
                          height: "18px", 
                          width: "18px", 
                          color: sphereColors.blue500,
                          position: "absolute",
                          right: 0,
                          top: "1px",
                        }}
                      />
                    )}
                  </Stack>
              }
              secondary={option.description}
            />
          </Box>
      )}
        renderInput={(params) => (
          <FaroSimpleTextField
            {...params}
            placeholder={placeholder}
            autoFocus={false}
            variant="outlined"
            sx={ styles.input.sx }
          />
        )}
        
        PaperComponent={link ? (props) => (
          <Paper {...props}
            sx={styles.paper.sx} 
            onMouseDown={(event) => event.preventDefault()}>
            {props.children}
              <Box>
                <SphereDivider 
                  sx={{ 
                    height:"2px", 
                    padding:"4px 0px", 
                    gap: "12px", 
                    color:sphereColors.gray200, 
                    alignSelf: "stretch",
                  }} 
                />
                <ExternalLinks
                  link={link}
                  text="Learn about permissions levels"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    fontSize: "11px",
                    fontWeight: 600,
                    gap: "12px",
                    padding: "8px",
                    flex: "1 0 0",
                    color: sphereColors.blue500,
                  }}
                />
            </Box>
          </Paper>
        ): Paper}
      />
    </>
  );
}